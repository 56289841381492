<template>
  <div class="case-all-container">
    <div class="case-all-top">
      <div class="case-all-top-title">
        {{ $t('HR001.queryConditionTitle') }}
      </div>
      <div class="case-all-top-condition">
        <div class="case-all-top-condition-city">
          <a-select
            ref="select"
            v-model:value="selectedCity"
            style="width: 130px"
            :options="cityOptions"
            :placeholder="placeHolderChooseCity"
            :disabled-date="disabledDate"
            @change="onChangeCity"
          ></a-select>
        </div>
        <div class="case-all-top-condition-district">
          <a-select
            ref="select"
            v-model:value="selectedDistrict"
            style="width: 130px"
            :options="districtOptions"
            :placeholder="placeHolderChooseDistrict"
            @change="onChangeDistrict"
          ></a-select>
        </div>
        <div class="case-all-top-condition-village">
          <a-select
            ref="select"
            v-model:value="selectedVillage"
            style="width: 220px"
            :options="villageOptions"
            :placeholder="placeHolderChooseVillage"
          ></a-select>
        </div>
        <div class="case-all-top-condition-date">
          <a-range-picker
            style="width: 220px"
            :ranges="ranges"
            show-time
            v-model:value="fromToDatelist"
            format="YYYY/MM/DD"
            @change="onChangeDate"
          />
        </div>
        <div class="case-all-top-condition-name">
          <a-input v-model:value="name" :placeholder="placeHolderChooseName" />
        </div>
      </div>

      <div class="case-all-top-confirm">
        <a-button
          class="confirm-button"
          @click="onClickQueryBtn"
          shape="round"
          :disabled="isQueryBtnDisable"
        >
          {{ $t('HR001.query') }}
        </a-button>
      </div>
      <div class="case-all-top-export">
        <a-button
          class="export-button"
          @click="clickExport"
          :disabled="!isExportON"
          :loading="isExportLoading"
        >
          <template #icon><ExportOutlined /></template>
          {{ $t('HR001.export') }}
        </a-button>
      </div>
    </div>
    <div class="case-all-detail">
      <MeasurementsAllTable
        :fromDate="fromDate"
        :toDate="toDate"
        :pageSize="pagnation.size"
        @onPageChange="onPageChange"
      />
    </div>
  </div>
</template>
<script>
  import dayjs from 'dayjs'
  import moment from 'moment'
  import { mapActions, mapGetters } from 'vuex'
  import MeasurementsAllTable from './components/MeasurementsAllTable'
  import { getDealerMeasurementsExport } from '@/api/measurement'
  import { ExportOutlined } from '@ant-design/icons-vue'

  export default {
    name: 'Measurements',
    components: { MeasurementsAllTable, ExportOutlined },
    data() {
      const ranges = {}
      ranges[this.$t('DatePicker.today')] = [moment(), moment()]
      ranges[this.$t('DatePicker.yesterday')] = [
        moment().add(-1, 'days'),
        moment().add(-1, 'days'),
      ]
      ranges[this.$t('DatePicker.thisMonth')] = [
        moment().startOf('month'),
        moment().endOf('month'),
      ]
      ranges[this.$t('DatePicker.latestMonth')] = [
        moment().add(-1, 'months'),
        moment(),
      ]
      ranges[this.$t('DatePicker.latestThreeMonths')] = [
        moment().add(-3, 'months'),
        moment(),
      ]
      ranges[this.$t('DatePicker.latestYear')] = [
        moment().add(-1, 'years').add(1, 'days'),
        moment(),
      ]
      return {
        placeHolderChooseGroup: this.$t('HR001.chooseGroup'),
        placeHolderChooseName: this.$t('HR001.chooseName'),
        // placeHolderChooseAbnormalItem: this.$t('HR001.chooseAbnormalItem'),
        fromToDatelist: [],
        fromDate: 0,
        toDate: 0,
        ranges: ranges,
        selectedGroup: [],
        groupOptions: [],
        selectedCondition: [],
        placeHolderChooseCity: this.$t('DealerDashboard.chooseCity'),
        placeHolderChooseDistrict: this.$t('DealerDashboard.chooseDistrict'),
        placeHolderChooseVillage: this.$t('DealerDashboard.chooseVillage'),
        selectedCity: null,
        selectedDistrict: null,
        selectedVillage: null,
        name: '',
        cityOptions: [],
        districtOptions: [],
        villageOptions: [],
        pagnation: {
          size: 100000,
          number: 1,
        },
        isExportLoading: false,
      }
    },
    computed: {
      ...mapGetters({
        // groups: 'group/groups',
        conditionForMeasurementDataAll:
          'measurement/conditionForMeasurementDataAll',
        dealerClient: 'statistics/dealerClient',
      }),
      isQueryBtnDisable() {
        let isQueryBtnDisable = true
        if (
          this.fromDate > 0 &&
          this.toDate > 0 &&
          this.selectedCity &&
          this.selectedDistrict &&
          this.selectedVillage
        ) {
          isQueryBtnDisable = false
        }

        return isQueryBtnDisable
      },
      isExportON() {
        if (
          this.fromDate > 0 &&
          this.toDate > 0 &&
          this.selectedCity &&
          this.selectedDistrict &&
          this.selectedVillage
        ) {
          return true
        }
        return false
      },
    },
    // updated() {
    //   console.log('==updated')
    // },
    // created() {
    //   console.log('==created')
    // },
    async mounted() {
      this.clearMeasurementDataAll()
      await this.getDealerClient()
      if (
        this.conditionForMeasurementDataAll &&
        this.conditionForMeasurementDataAll.timeRange &&
        this.conditionForMeasurementDataAll.timeRange.startTime > 0 &&
        this.conditionForMeasurementDataAll.timeRange.endTime > 0
      ) {
        this.fromToDatelist.push(
          moment(this.conditionForMeasurementDataAll.timeRange.startTime)
        )
        this.fromToDatelist.push(
          moment(this.conditionForMeasurementDataAll.timeRange.endTime)
        )
        this.fromDate = this.conditionForMeasurementDataAll.timeRange.startTime
        this.toDate = this.conditionForMeasurementDataAll.timeRange.endTime
      } else {
        const today = moment()
        const threeMonthAgo = moment().add('M', '-3')
        this.fromDate = threeMonthAgo.unix() * 1000
        this.toDate = today.unix() * 1000
        this.fromToDatelist = []
        this.fromToDatelist.push(threeMonthAgo)
        this.fromToDatelist.push(today)
        this.onClickQueryBtn()
      }
      // this.getGroupList()
      this.getMeasureThreshold()
    },
    methods: {
      ...mapActions({
        // getGroupList: 'group/getGroupList',
        getMeasurementDataAllByDealerStation:
          'measurement/getMeasurementDataAllByDealerStation',
        clearMeasurementDataAll: 'measurement/clearMeasurementDataAll',
        getMeasureThreshold: 'measureThreshold/getMeasureThreshold',
        getDealerClient: 'statistics/getDealerClient',
      }),
      // updateGroupOptions() {
      //   this.groupOptions = []
      //   if (!this.groups) {
      //     return
      //   }

      //   this.groups.forEach((item) => {
      //     this.groupOptions.push({
      //       value: item.groupId.toString(),
      //       label: item.title,
      //     })
      //   })
      // },
      // onChangeCondition(selectedCondition) {
      //   for (let i = 0; i < selectedCondition.length; i++) {
      //     if (selectedCondition[i] === '0') {
      //       this.selectedCondition = ['0']
      //       return
      //     }
      //   }
      //   console.log(selectedCondition)
      // },
      onChangeDate(date, dateString) {
        if (dateString && dateString.length == 2) {
          const fromDate = dayjs(dateString[0]).startOf('day').unix()
          const toDate = dayjs(dateString[1]).endOf('day').unix()
          console.log(fromDate)
          console.log(toDate)
          this.fromDate = fromDate * 1000
          this.toDate = toDate * 1000
        }
      },
      onChangeCity() {
        this.updateDistrict()
        this.updateVillageOption()
      },
      onChangeDistrict() {
        this.updateVillageOption()
      },
      updateDistrictOption() {
        this.districtOptions = []
        this.selectedDistrict = null
        if (this.selectedCity) {
          const filterItems = this.dealerClient.filter(
            (item) => item.city === this.selectedCity
          )
          const districts = [
            ...new Set(filterItems.map((item) => item.district)),
          ]
          districts.forEach((name) => {
            this.districtOptions.push({
              value: name,
              label: name,
            })
          })
          this.selectedDistrict =
            this.districtOptions.length > 0
              ? this.districtOptions[0].value
              : null
        }
      },
      updateVillageOption() {
        this.villageOptions = []
        this.selectedVillage = null
        if (this.selectedDistrict) {
          const villages = this.dealerClient.filter(
            (item) => item.district === this.selectedDistrict
          )
          villages.forEach((item) => {
            this.villageOptions.push({
              value: item.title,
              label: item.title,
            })
          })
          this.selectedVillage =
            this.villageOptions.length > 0 ? this.villageOptions[0].value : null
        }
      },
      onClickQueryBtn() {
        if (this.fromDate <= 0 || this.toDate <= 0) {
          return
        }
        const postBody = {
          timeRange: {
            startTime: this.fromDate,
            endTime: this.toDate,
          },
        }

        if (this.selectedGroup.length > 0) {
          postBody.groupIDs = []
          this.selectedGroup.forEach((item) => {
            postBody.groupIDs.push(parseInt(item, 10))
          })
        }

        if (this.selectedCondition.length > 0) {
          postBody.abnormalConditions = []
          for (let i = 0; i < this.selectedCondition.length; i++) {
            let numValue = parseInt(this.selectedCondition[i], 10)
            if (isNaN(numValue) === false && numValue === 0) {
              postBody.abnormalConditions = [0]
              break
            }
            if (isNaN(numValue) && numValue > 0) {
              postBody.abnormalConditions.push(numValue)
            }
          }
        }

        if (this.name) {
          postBody.name = this.name
        }
        postBody.pagnation = this.pagnation

        const selectedClientInfo = this.dealerClient.find(
          (item) =>
            item.city === this.selectedCity &&
            item.district === this.selectedDistrict &&
            item.title === this.selectedVillage
        )
        postBody.code = selectedClientInfo?.code || '0'
        console.log(postBody)
        this.getMeasurementDataAllByDealerStation(postBody)
      },
      async clickExport() {
        const selectedClientInfo = this.dealerClient.find(
          (item) =>
            item.city === this.selectedCity &&
            item.district === this.selectedDistrict &&
            item.title === this.selectedVillage
        )
        if (!selectedClientInfo?.code) {
          return
        }

        this.isExportLoading = true
        try {
          if (this.fromDate == 0 && this.toDate == 0) {
            return
          }
          const params = {
            startTime: this.fromDate,
            endTime: this.toDate,
          }
          if (this.name) {
            params.name = this.name
          }
          if (this.selectedGroup.length > 0) {
            params.groups = this.selectedGroup.join(',')
            // params.groups = []
            // this.selectedGroup.forEach((item) => {
            //   params.groups.push(parseInt(item, 10))
            // })
          }
          params.lang = 'tw'
          params.code = selectedClientInfo?.code
          const respData = await getDealerMeasurementsExport(params)
          // console.log(respData)
          // console.log(respData.headers['Content-Disposition'])
          // console.log(respData.headers['content-disposition'])

          let fileName = 'Report.xlsx'
          let contentDisposition = respData.headers['content-disposition']
          if (!contentDisposition) {
            contentDisposition = respData.headers['Content-Disposition']
          }
          if (contentDisposition) {
            fileName = contentDisposition.split(' ')[1].replace('filename=', '')
          }

          const url = window.URL.createObjectURL(new Blob([respData.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          this.isExportLoading = false
        } catch (e) {
          this.isExportLoading = false
          console.log(e)
        }
      },
      disabledDate(current) {
        return current && current < moment().add(-1, 'years')
      },
      onPageChange(inPagination) {
        this.pagnation.number = inPagination.current
        this.onClickQueryBtn()
      },
    },
    watch: {
      // groups: function () {
      //   this.updateGroupOptions()
      // },
      dealerClient: function () {
        const citys = [...new Set(this.dealerClient.map((item) => item.city))]
        this.cityOptions = []
        citys.forEach((name) => {
          this.cityOptions.push({
            value: name,
            label: name,
          })
        })

        let clientInfo = null
        if (this.$route?.query?.district) {
          clientInfo = this.dealerClient.find(
            (item) => this.$route?.query?.district === item.district
          )
        }
        if (clientInfo?.city) {
          this.selectedCity = clientInfo.city
        } else {
          this.selectedCity =
            this.cityOptions.length > 0 ? this.cityOptions[0].value : null
        }

        this.updateDistrictOption()
        this.updateVillageOption()

        if (this.$route?.query?.village) {
          const clientInfo = this.dealerClient.find(
            (item) => this.$route?.query?.village === item.title
          )
          this.selectedCity = clientInfo?.city || this.selectedCity
          this.selectedDistrict = clientInfo?.district || this.selectedDistrict
          this.selectedVillage = clientInfo?.title || this.selectedVillage
        }
      },
    },
  }
</script>
<style lang="less" scoped>
  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }

  .case-all-container {
    padding-top: 28px;
    padding-left: 31px;
    padding-right: 31px;
    background-color: #e5e5e5;

    .case-all-top {
      .clear-fix-float();
      //margin-bottom: 20px;
      //height: 100px;
      min-height: 100px;
      height: auto;
      background-color: #ffffff;
      border-radius: 10px;

      .case-all-top-title {
        width: 72;
        height: 32;
        float: left;
        margin-top: 40px;
        margin-left: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        /* identical to box height, or 89% */

        display: flex;
        align-items: center;

        color: rgba(0, 0, 0, 0.5);
      }

      .case-all-top-condition {
        width: 600px;
        margin-top: 15px;
        float: left;
        .case-all-top-condition-city {
          width: 130;
          height: 16;
          float: left;
          margin-top: 5px;
          margin-left: 15px;
        }
        .case-all-top-condition-district {
          width: 130;
          height: 16;
          float: left;
          margin-top: 5px;
          margin-left: 15px;
        }
        .case-all-top-condition-village {
          width: 220;
          height: 16;
          float: left;
          margin-top: 5px;
          margin-left: 15px;
        }
        .case-all-top-condition-date {
          width: 220;
          height: 16;
          float: left;
          margin-top: 5px;
          margin-left: 15px;
        }
        .case-all-top-condition-name {
          width: 200;
          height: 16;
          float: left;
          margin-top: 5px;
          margin-left: 15px;
        }
      }

      .case-all-top-confirm {
        width: 85;
        height: 40;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .confirm-button {
          color: white;
          background: #4dbaa7;
          border-radius: 6px;
          &:focus {
            -webkit-appearance: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
      .case-all-top-export {
        width: 110px;
        height: 40;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .export-button {
          &:focus {
            -webkit-appearance: none;
            border-color: #d9d9d9;
            color: rgba(0, 0, 0, 0.85);
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .case-all-detail {
      .clear-fix-float();
      margin-top: 20px;
      //border-radius: 25px;
      //background-color: cornflowerblue;
      width: 100%;
      //height: 200px;
      background: #ffffff;

      .case-list-top {
        height: 75px;
        .clear-fix-float();
        .new-case-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
      }
    }
  }
</style>
