import { render } from "./index.vue?vue&type=template&id=cdab08d8"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "cdab08d8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('cdab08d8', script)) {
    api.reload('cdab08d8', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=cdab08d8", () => {
    api.rerender('cdab08d8', render)
  })

}

script.__file = "src/views/measurements/index.vue"

export default script