import { render } from "./DealerLayout.vue?vue&type=template&id=2c399f71&scoped=true"
import script from "./DealerLayout.vue?vue&type=script&lang=js"
export * from "./DealerLayout.vue?vue&type=script&lang=js"

import "./DealerLayout.vue?vue&type=style&index=0&id=2c399f71&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-2c399f71"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2c399f71"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2c399f71', script)) {
    api.reload('2c399f71', script)
  }
  
  module.hot.accept("./DealerLayout.vue?vue&type=template&id=2c399f71&scoped=true", () => {
    api.rerender('2c399f71', render)
  })

}

script.__file = "src/views/measurements/DealerLayout.vue"

export default script