<template>
  <div>
    <ClientLayout v-if="isClient()" />
    <DealerLayout v-if="isDealer()" />
  </div>
</template>

<script>
  // import DashboardVisit from './components/DashboardVisit'
  // import DashboardClassified from './components/DashboardClassified'
  import ClientLayout from './ClientLayout'
  import DealerLayout from './DealerLayout'
  import { mapGetters } from 'vuex'
  import { accountExpiredCheck } from '../common/accountExpiredCheck.js'

  export default {
    name: 'Index',
    data() {
      return {}
    },
    components: { ClientLayout, DealerLayout },
    mounted() {
      accountExpiredCheck(this.expiredTs)
    },
    computed: {
      ...mapGetters({
        title: 'user/title',
        role: 'user/role',
        expiredTs: 'user/expiredTs',
      }),
    },
    methods: {
      isClient() {
        if (this.role === 'client') {
          return true
        }
        return false
      },
      isDealer() {
        if (this.role === 'dealer') {
          return true
        }
        return false
      },
    },
  }
</script>
<style lang="less" scoped></style>
