import { render } from "./MeasurementsAllTable.vue?vue&type=template&id=5b868b82&scoped=true"
import script from "./MeasurementsAllTable.vue?vue&type=script&lang=js"
export * from "./MeasurementsAllTable.vue?vue&type=script&lang=js"

import "./MeasurementsAllTable.vue?vue&type=style&index=0&id=5b868b82&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-5b868b82"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5b868b82"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5b868b82', script)) {
    api.reload('5b868b82', script)
  }
  
  module.hot.accept("./MeasurementsAllTable.vue?vue&type=template&id=5b868b82&scoped=true", () => {
    api.rerender('5b868b82', render)
  })

}

script.__file = "src/views/measurements/components/MeasurementsAllTable.vue"

export default script