import { render } from "./ClientLayout.vue?vue&type=template&id=8fee02ba&scoped=true"
import script from "./ClientLayout.vue?vue&type=script&lang=js"
export * from "./ClientLayout.vue?vue&type=script&lang=js"

import "./ClientLayout.vue?vue&type=style&index=0&id=8fee02ba&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-8fee02ba"
/* hot reload */
if (module.hot) {
  script.__hmrId = "8fee02ba"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8fee02ba', script)) {
    api.reload('8fee02ba', script)
  }
  
  module.hot.accept("./ClientLayout.vue?vue&type=template&id=8fee02ba&scoped=true", () => {
    api.rerender('8fee02ba', render)
  })

}

script.__file = "src/views/measurements/ClientLayout.vue"

export default script