import { render } from "./MeasurementsAllTableDetail.vue?vue&type=template&id=247184f0&scoped=true"
import script from "./MeasurementsAllTableDetail.vue?vue&type=script&lang=js"
export * from "./MeasurementsAllTableDetail.vue?vue&type=script&lang=js"

import "./MeasurementsAllTableDetail.vue?vue&type=style&index=0&id=247184f0&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-247184f0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "247184f0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('247184f0', script)) {
    api.reload('247184f0', script)
  }
  
  module.hot.accept("./MeasurementsAllTableDetail.vue?vue&type=template&id=247184f0&scoped=true", () => {
    api.rerender('247184f0', render)
  })

}

script.__file = "src/views/measurements/components/MeasurementsAllTableDetail.vue"

export default script